import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import avatar from './avatar.svg';
import bg from './bg.svg';
import wave from './wave.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Container = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '7rem',
  padding: '0 2rem',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  minHeight: '100vh',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gridGap: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem',
  },
}));

const ImgBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginBottom: '2rem',
  },
}));

const ImgInner = styled('img')({
  width: '500px',
  maxWidth: '100%',
  objectFit: 'cover',
  display: 'block',
});

const LoginContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    transform: 'translateY(-80px)',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    transform: 'translateY(-80px)',
  },
}));

const Form = styled('form')({
  width: '360px',
  maxWidth: '100%',
});

const Avatar = styled('img')({
  height: '100px',
});

const Title = styled(Typography)(({ theme }) => ({
  margin: '15px 0',
  color: '#333',
  textTransform: 'uppercase',
  fontSize: '2.9rem',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '50px',
  borderRadius: '25px',
  outline: 'none',
  border: '1px solid black',
  backgroundImage: 'linear-gradient(to right, #32be8f, #38d39f, #32be8f)',
  backgroundSize: '200%',
  fontSize: '1.2rem',
  color: '#fff',
  textTransform: 'uppercase',
  margin: '1rem 0',
  cursor: 'pointer',
  transition: '.5s',
  '&:hover': {
    backgroundPosition: 'right',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    height: '40px',
  },
}));

const ErrorText = styled(Typography)({
  color: 'red',
});

const LoginForm = () => {
  const navigate = useNavigate();
  const [focusState, setFocusState] = useState({
    email: false,
    password: false,
  });
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); 

  useEffect(() => {
    setFocusState({
      email: !!formData.email,
      password: !!formData.password,
    });
  }, [formData]);

  const handleFocus = (field) => {
    setFocusState((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field, value) => {
    if (!value) {
      setFocusState((prev) => ({ ...prev, [field]: false }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const loginData = {
      email: formData.email,
      password: formData.password,
    };

    try {
      const response = await fetch('https://backend.smartcapital.co.in/admin/adminLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const data = await response.json();

        const adminToken = data.adminToken;
        const adminId = data.userData.adminId;
        const role = data.userData.role;
        sessionStorage.setItem('role', role);
        sessionStorage.setItem('adminToken', adminToken);
        sessionStorage.setItem('adminId', adminId);

        navigate('/Stocklist');
      } else if (response.status === 404) {
        setError('No user found. Please check your email and password.');
      } else {
        setError('An error occurred while logging in.');
      }
    } catch (error) {
      setError('Failed to connect to the server.');
    } finally {
      setLoading(false);
    }
};


  return (
    <Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: -1,
          width: '100%',
        }}
      >
        <img
          src={wave}
          alt="Wave"
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
          }}
        />
      </Box>

      <ImgBox>
        <ImgInner
          src={bg}
          alt="Background"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
          }}
        />
      </ImgBox>

      <LoginContent>
        <Form onSubmit={handleSubmit}>
          <Avatar src={avatar} alt="Avatar" />
          <Title>Welcome</Title>

          <TextField
            label="Email"
            name="email"
            variant="outlined"
            value={formData.email}
            onFocus={() => handleFocus('email')}
            onBlur={(e) => handleBlur('email', e.target.value)}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
              style: { color: 'black' },
            }}
            InputProps={{
              style: { color: 'black' },
              value: formData.email,
            }}
            focused={focusState.email}
          />

          <TextField
            label="Password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            value={formData.password}
            onFocus={() => handleFocus('password')}
            onBlur={(e) => handleBlur('password', e.target.value)}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
              style: { color: 'black' },
            }}
            InputProps={{
              style: { color: 'black' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)} 
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            focused={focusState.password}
          />
          {error && <ErrorText>{error}</ErrorText>}

          <SubmitButton type="submit" disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
          </SubmitButton>
        </Form>
      </LoginContent>
    </Container>
  );
};

export default LoginForm;
