import React, { useEffect, useState } from "react";
import { Box, InputBase, IconButton, Checkbox, Button, Skeleton, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import config from '../../Config';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Cookies from 'js-cookie';


const Stocklist = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const apiUrl = `${config.apiBaseUrl}/admin/allStocks`;
      const response = await fetch(apiUrl);
      const data = await response.json();

      const formattedData = data.map((item) => ({
        id: item.instrument_token,
        instrument_token: item.instrument_token,
        exchange: item.exchange,
        tradingsymbol: item.tradingsymbol,
        segment: item.segment,
        last_price: item.last_price,
        isActive: item.isActive,
        isUpdated: item.isActive,
        logo: item.logo || null,
      }));

      setRows(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredRows = rows.filter((row) =>
    row.tradingsymbol.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (instrument_token) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.instrument_token === instrument_token
          ? { ...row, isUpdated: !row.isUpdated }
          : row
      )
    );
  };

  const handleImageChange = (event, instrument_token) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage({ instrument_token, file });
    }
  };

  const handleUpdateAll = async () => {
    const Token = sessionStorage.getItem('adminToken');
    const updatedStocks = rows.filter(row => row.isUpdated);

    if (updatedStocks.length === 0) {
      Swal.fire({
        title: 'No Updates Selected',
        text: 'Please select stocks to update.',
        icon: 'warning',
      });
      return;
    }

    const updatedStockPayload = updatedStocks.map(stock => ({
      token: stock.instrument_token,
      marketType: stock.exchange,
    }));

    Swal.fire({
      title: 'Updating Stocks...',
      text: 'Please wait while we update the stock status.',
      icon: 'info',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const apiUrl = `${config.apiBaseUrl}/admin/addActiveStock`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": Token,
        },
        body: JSON.stringify({
          instrumentTokens: updatedStockPayload,
        }),
      });

      const result = await response.json();

      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: result.message || 'Stock status updated successfully.',
          icon: 'success',
        });

        await fetchData(); 
      } else {
        Swal.fire({
          title: 'Error!',
          text: result.message || 'Failed to update the stocks.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error updating stock status:", error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while updating the stocks.',
        icon: 'error',
      });
    }
  };

  const handleImageUpload = async (instrument_token) => {
    if (!selectedImage) {
      Swal.fire({
        title: 'No Image Selected',
        text: 'Please select an image for a stock.',
        icon: 'warning',
      });
      return;
    }

    const { instrument_token: selectedToken, file } = selectedImage;
    if (instrument_token !== selectedToken) {
      Swal.fire({
        title: 'Image Not Selected',
        text: 'Please select an image for the correct stock.',
        icon: 'warning',
      });
      return;
    }

    const formData = new FormData();
    formData.append('stockLogo', file);
    formData.append('instrumentToken', instrument_token);
    formData.append('marketType', rows.find(row => row.instrument_token === instrument_token).exchange);

    Swal.fire({
      title: 'Uploading Image...',
      text: 'Please wait while the image is being uploaded.',
      icon: 'info',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const apiUrl = `${config.apiBaseUrl}/admin/updateStockLogo`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "x-access-token": sessionStorage.getItem('adminToken'),
        },
        body: formData,
      });

      const result = await response.json();

      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: result.message || 'Image uploaded successfully.',
          icon: 'success',
        });
        await fetchData();
        setSelectedImage(null);
      } else {
        Swal.fire({
          title: 'Error!',
          text: result.message || 'Failed to upload the image.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while uploading the image.',
        icon: 'error',
      });
    }
  };
  
   const saveInstrumentData = async () => {
    Swal.fire({
      title: 'Saving Instrument Data...',
      text: 'Please wait while we save the instrument data.',
      icon: 'info',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const apiUrl = `${config.apiBaseUrl}/admin/getInstrumentAndSave`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization-Token": "yvcrugkmL8uozAJAJlZgf3bgfa3B1iRZ",
        },
      });

      if (response.status === 200) {
        const result = await response.json();

        Swal.fire({
          title: 'Success!',
          text: result.message || 'Instrument data saved successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#3085d6',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to save instrument data. Please try again.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error saving instrument data:", error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while saving the data.',
        icon: 'error',
      });
    }
  };


  const updateInstrumentData = async () => {
    Swal.fire({
      title: 'Updating Instrument Data...',
      text: 'Please wait while the update request is being processed.',
      icon: 'info',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
       const accessToken = Cookies.get('accessToken')
       console.log(accessToken);
      const apiUrl = `${config.apiBaseUrl}/admin/updateInstrumentByQuotes`;
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "access-token": "yvcrugkmL8uozAJAJlZgf3bgfa3B1iRZ",
        },
      });

      if (response.status === 200) {
        const result = await response.json();
        Swal.fire({
          title: 'Update Successful!',
          text: result.message || 'Instrument data updated successfully.',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update instrument data. Please try again.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error updating instrument data:", error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while updating the data.',
        icon: 'error',
      });
    }
  };

  const columns = [
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.isUpdated}
          onChange={() => handleCheckboxChange(params.row.instrument_token)}
          disabled={params.row.isActive}
        />
      ),
    },
    {
      field: "logo",
      headerName: "Logo",
      flex: 1,
      renderCell: (params) => {
        const logoUrl = params.row.logo;
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              height: '100%',
            }}
          >
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="Logo"
                style={{ width: 90, height: 50, objectFit: 'contain' }}
              />
            ) : (
              <Typography variant="caption" color="textSecondary">
              </Typography>
            )}
          </Box>
        );
      },
    },
    { field: "id", headerName: "Instrument Token", flex: 1 },
    { field: "tradingsymbol", headerName: "Trading Symbol", flex: 1 },
    { field: "exchange", headerName: "Exchange", flex: 1 },
    { field: "last_price", headerName: "Last Price", flex: 1 },
    {
      field: "image",
      headerName: "Upload Image",
       flex: 1,
      renderCell: (params) => {
        const instrument_token = params.row.instrument_token;
        const isImageSelected = selectedImage?.instrument_token === instrument_token;

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 1,
              height: '100%',
            }}
          >
            <label htmlFor={`upload-button-${instrument_token}`} style={{ cursor: 'pointer' }}>
              <AddCircleOutlineIcon
                sx={{
                  fontSize: 30,
                  color: isImageSelected ? 'green' : 'gray',
                  verticalAlign: 'middle',
                }}
              />
            </label>

            <input
              id={`upload-button-${instrument_token}`}
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, instrument_token)}
              style={{
                display: "none",
              }}
            />

            {isImageSelected && (
              <Typography variant="caption" color="green" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {selectedImage.file.name}
              </Typography>
            )}
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => handleImageUpload(instrument_token)}
            >
              Upload
            </Button>
          </Box>
        );
      },
    }
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  const isSuperAdmin = sessionStorage.getItem("role") === "Super_Admin";

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
        <Header title="STOCK LIST" subtitle="List Of Stocks For Managing." />
        <Box display="flex" gap="10px">
          {isSuperAdmin && (
            <>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "200px" }}
                onClick={saveInstrumentData}
              >
                Save Instrument Data
              </Button>

              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "200px" }}
                onClick={updateInstrumentData}
              >
                Update Instrument Data
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-start" width="100%" mb="10px">
        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px" sx={{ width: "300px" }}>
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
            marginBottom: "20px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading ? renderShimmer() : <DataGrid rows={filteredRows} columns={columns} components={{ Toolbar: GridToolbar }} />}
      </Box>
      <Box display="flex" justifyContent="flex-end" mb="10px">
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "200px" }}
          onClick={handleUpdateAll}
        >
          Update Active Stocks
        </Button>
      </Box>
    </Box>
  );
};

export default Stocklist;
