import React, { useEffect, useState } from "react";
import { Box, InputBase, IconButton, Skeleton, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Headers/Header"; 
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import config from '../../Config';

const TopCards = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]); 
  const [searchQuery, setSearchQuery] = useState(""); 
  const [loading, setLoading] = useState(true); 
  const [changedRows, setChangedRows] = useState([]); 

  const navigate = useNavigate(); 

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const apiUrl = `${config.apiBaseUrl}/admin/getDashCard`
      const response = await fetch(apiUrl);
      const data = await response.json();

      const formattedData = data.dashboardData.map((item) => ({
        id: item.instrument_token,
        name: item.name,
        exchange: item.exchange,
        isChecked: true, 
      }));

      setRows(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch data. Please try again.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredRows = rows.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (id, isChecked) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, isChecked } : row
    );
    setRows(updatedRows); 

    if (!isChecked) {
      setChangedRows((prev) => [...prev, id]); 
    } else {
      setChangedRows((prev) => prev.filter((rowId) => rowId !== id)); 
    }
  };

  const handleUpdateClick = async () => {
    if (changedRows.length === 0) {
      Swal.fire({
        title: "No changes",
        text: "Please uncheck some stocks to remove them.",
        icon: "info",
        confirmButtonText: "Okay",
      });
      return;
    }

    try {
      const apiUrl = `${config.apiBaseUrl}/admin/removeDashCard`;

      const token = sessionStorage.getItem("adminToken");

      if (!token) {
        Swal.fire({
          title: "Unauthorized",
          text: "Please log in again to perform this action.",
          icon: "warning",
          confirmButtonText: "Okay",
        });
        return;
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token, 
        },
        body: JSON.stringify({ instrument_token: changedRows.map(Number) }),
      });

      const resultData = await response.json();

      if (response.status === 200) {
        Swal.fire({
          title: "Success",
          text: resultData.message || "Successfully removed the selected stocks.",
          icon: "success",
          confirmButtonText: "Okay",
        });

        const updatedRows = rows.filter((row) => !changedRows.includes(row.id));
        setRows(updatedRows);
        setChangedRows([]);
      } else {
        Swal.fire({
          title: "Error",
          text: resultData.message || "Failed to remove. Please try again.",
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    } catch (error) {
      console.error("Error removing from API:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to remove stocks. Please try again.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };


  const columns = [
    { field: "id", headerName: "Instrument Token", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "exchange", headerName: "Exchange", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <Checkbox
          checked={params.row.isChecked}
          onChange={() => handleCheckboxChange(params.row.id, !params.row.isChecked)}
          sx={{
            color: colors.greenAccent[200],
            '&.Mui-checked': {
              color: colors.greenAccent[200],
            },
          }}
        />
      ),
      flex: 1,
    },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  const handleAddDashboardCard = () => {
    navigate("/addDashCard"); 
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="TOP CARDS" subtitle="Overview of Top Cards." />

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "200px", marginBottom: "10px" }} 
            onClick={handleAddDashboardCard} 
          >
            Add Dashboard Card
          </Button>

          <Box width="300px" display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box
        mb="20px" 
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        {loading ? (
          renderShimmer()
        ) : (
          <DataGrid
            rows={filteredRows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>

      <Box display="flex" justifyContent="flex-end" mt="20px">
        <Button variant="contained" color="secondary" onClick={handleUpdateClick}>
          Update Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default TopCards;
