import React, { useState, useEffect } from "react";
import { Box, Button as MuiButton, Snackbar, Alert, Typography, Card, CardContent, TextField, Grid, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import PersonIcon from "@mui/icons-material/Person";
import { green, red, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";  
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; 
import config from '../../Config';


const staticUserData = {
  portfolio: {
    totalInvestment: 5000,
    oneDayProfitLoss: 200,
    allTimeProfitLoss: 1500,
    availableWalletAmount: 2500,
  },
};

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1a1a1a" : "#fff",
  borderRadius: "16px",
  boxShadow: theme.palette.mode === "dark" ? "0 6px 15px rgba(0, 0, 0, 0.2)" : "0 10px 20px rgba(0, 0, 0, 0.1)",
  padding: "24px",
  marginBottom: "20px",
  border: `1px solid ${theme.palette.mode === "dark" ? "#333" : "#ccc"}`,
  display: "flex", 
  flexDirection: "column",
  height: "100%", 
  overflow: "hidden",
}));

const PortfolioCard = styled(StyledCard)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.paper : "#f9fafb", 
  border: `1px solid ${theme.palette.mode === "dark" ? grey[700] : "#e1e1e1"}`, 
  boxShadow: theme.palette.mode === "dark" ? "0 4px 12px rgba(0, 0, 0, 0.2)" : "0 4px 12px rgba(0, 0, 0, 0.1)",
  position: "relative", 
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "15px",
  marginBottom: "20px",
}));

const UserDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [userData, setUserData] = useState({
    ...staticUserData,
    marginSetting: "", 
  });
  const [walletData, setWalletData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { userId } = useParams();
  const navigate = useNavigate();

  const { portfolio } = staticUserData;

  useEffect(() => {
    const token = sessionStorage.getItem("adminToken");

    if (token && userId) {
      const apiUrl = `${config.apiBaseUrl}/admin/getUserById`;
      fetch(apiUrl, {
        method: "GET",
        headers: {
          "x-access-token": token,
          "user-id": userId,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch user data");
          }
        })
        .then((data) => {
          if (data && data.walletData) {
            setWalletData(data.walletData);
            setUserData({
              ...userData,
              ...data.userData, 
              marginSetting: data.userData.marginSetting || "", 
            });
          } else {
            setSnackbarMessage("Failed to fetch wallet data");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        })
        .catch((error) => {
          setSnackbarMessage("Error fetching wallet data");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarMessage("Missing token or user-id in session");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [userId]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = () => {
    const token = sessionStorage.getItem("adminToken");
    const marginValue = userData.marginSetting;

    if (!marginValue) {
      setSnackbarMessage("Margin value cannot be empty!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (token && userId) {
      const apiUrl = `${config.apiBaseUrl}/admin/setMarginLimit`;
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({
          userId,
          margin: marginValue,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to update margin");
          }
        })
        .then((data) => {
          setSnackbarMessage("Margin successfully updated!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        })
        .catch((error) => {
          setSnackbarMessage("Error updating margin");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarMessage("Missing token or user-id in session");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleMarginChange = (e) => {
    setUserData({
      ...userData,
      marginSetting: e.target.value,
    });
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="flex-start" mb="20px">
        <IconButton 
          onClick={() => navigate(-1)} 
          sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} 
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="USER DETAILS" subtitle="Manage User Information" />
      </Box>

      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <PortfolioCard sx={{ height: "100%" }}>
            <CardContent sx={{ position: "relative", height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Total Investment:</strong>
                </Typography>
                <Typography variant="body1" color={colors.grey[200]}>
                  {portfolio.totalInvestment}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>1 Day Profit/Loss:</strong>
                </Typography>
                <Typography variant="body1" color={portfolio.oneDayProfitLoss > 0 ? green[500] : red[500]}>
                  {portfolio.oneDayProfitLoss}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>All-Time Profit/Loss:</strong>
                </Typography>
                <Typography variant="body1" color={portfolio.allTimeProfitLoss > 0 ? green[500] : red[500]}>
                  {portfolio.allTimeProfitLoss}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Available Wallet Balance:</strong>
                </Typography>
                <Typography variant="body1" color={colors.grey[200]}>
                  {portfolio.availableWalletAmount}
                </Typography>
              </Box>
            </CardContent>
          </PortfolioCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard sx={{ height: "100%" }}>
            <CardContent>
              <InfoContainer>
                <Box
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    backgroundColor: colors.grey[300],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <PersonIcon sx={{ fontSize: "40px", color: colors.primary[500] }} />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography variant="h6" color={colors.grey[100]} fontWeight="bold">
                      {userData.first_name} {userData.last_name}
                    </Typography>
                    <Typography variant="body1" color={colors.grey[300]}>
                      {userData.userId}
                    </Typography>
                  </Box>
                </Box>
              </InfoContainer>

              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                  <Typography variant="body1" color={colors.grey[200]}>
                    <strong>Email:</strong> {userData.email}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                  <Typography variant="body1" color={colors.grey[200]}>
                    <strong>Username:</strong> {userData.user_name}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <StyledCard sx={{ width: "100%", marginTop: "20px" }}>
        <CardContent>
          {walletData ? (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1" color={colors.grey[200]}>
                    <strong>Total Balance:</strong> {walletData.total_balance}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1" color={colors.grey[200]}>
                    <strong>Withdrawable Balance:</strong> {walletData.withdraw_balance}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1" color={colors.grey[200]}>
                    <strong>Pending Balance:</strong> {walletData.pending_balance}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Typography variant="body1" color={colors.grey[200]}>
              Loading wallet details...
            </Typography>
          )}

          <Box mt={2}>
            <TextField
              label="Set Margin"
              type="number"
              value={userData.marginSetting}
              onChange={handleMarginChange} 
              fullWidth
              variant="outlined"
              sx={{ marginBottom: "20px" }}
            />
            <MuiButton variant="contained" color="primary" fullWidth onClick={handleSubmit}>
              Submit
            </MuiButton>
          </Box>
        </CardContent>
      </StyledCard>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserDetails;
