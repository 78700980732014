import React, { useEffect, useState } from "react";
import { Box, InputBase, Snackbar, Alert, Skeleton, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from 'axios';
import config from '../../Config';
import { useNavigate } from 'react-router-dom'; 

const WithdrawRequests = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const navigate = useNavigate();  
  
  useEffect(() => {
    const fetchWithdrawData = async () => {
      try {
        const token = sessionStorage.getItem("adminToken");

        if (!token) {
          setSnackbarMessage("No admin token found. Please log in.");
          return;
        }
        const apiUrl = `${config.apiBaseUrl}/admin/getWithdrawRequests`;
        const response = await axios.get(apiUrl, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        });

        const data = response.data;
        if (data.withdrawRequests) {
          const formattedData = data.withdrawRequests.map((item) => ({
            id: item._id,
            withdrawId: item.requestId,
            userId: item.userId,
            amount: item.amount,
            bankId: item.bankId,
            status: item.status,
          }));
          setRows(formattedData);
        } else {
          setSnackbarMessage("No withdrawal data found.");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage("Error fetching withdrawal data.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawData();
  }, []);

  const filteredRows = rows.filter((row) => {
    const withdrawId = row.withdrawId ? row.withdrawId.toLowerCase() : '';
    const userId = row.userId ? row.userId.toLowerCase() : '';
    
    return (
      withdrawId.includes(searchQuery.toLowerCase()) ||
      userId.includes(searchQuery.toLowerCase())
    );
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleWithdrawIdClick = (withdrawId) => {
    navigate(`/withdraw-details/${withdrawId}`);  
  };

  const columns = [
    {
      field: "withdrawId",
      headerName: "Withdraw ID",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => handleWithdrawIdClick(params.row.withdrawId)} 
        >
          {params.row.withdrawId}
        </span>
      ),
    },
    { field: "userId", headerName: "User ID", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "bankId", headerName: "Bank ID", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.status === "Pending"
                ? "orange"
                : params.row.status === "Approved"
                ? "green"
                : "red",
          }}
        >
          {params.row.status}
        </span>
      ),
    },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="WITHDRAW REQUESTS" subtitle="Manage Withdraw Requests" />
        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search by Withdraw ID or User ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        mb="20px"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        {loading ? (
          renderShimmer()
        ) : (
          <DataGrid
            rows={filteredRows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 15]}
          />
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WithdrawRequests;
