import React, { useEffect, useState } from "react";
import { Box, InputBase, Snackbar, Alert, Button as MuiButton, Skeleton, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import config from '../../Config';

const KycRequests = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  // Reusable fetchKycData function
  const fetchKycData = async () => {
    try {
      const token = sessionStorage.getItem("adminToken");

      if (!token) {
        setSnackbarMessage("No admin token found. Please log in.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const apiUrl = `${config.apiBaseUrl}/admin/getKycRequestList`;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'x-access-token': token, 
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data.kycData) {
        const formattedData = data.kycData.map((item) => ({
          id: item.kycId, 
          kycId: item.kycId,
          firstName: item.userData.first_name,
          email: item.userData.email,
          contact: item.userData.contact, 
          acceptedBy: item.acceptedBy, 
          status: item.kycStatus,
        }));
        setRows(formattedData);
      } else {
        setSnackbarMessage("No KYC data found.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching KYC data:", error);
      setSnackbarMessage("Error fetching KYC data.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKycData();
  }, []);

  const filteredRows = rows.filter((row) => {
    const kycId = row.kycId ? row.kycId.toLowerCase() : '';
    const firstName = row.firstName ? row.firstName.toLowerCase() : '';
    const email = row.email ? row.email.toLowerCase() : '';
    
    return (
      kycId.includes(searchQuery.toLowerCase()) ||
      firstName.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase())
    );
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAccept = async (kycId) => {
      try {
        const token = sessionStorage.getItem("adminToken");
        if (!token) {
          setSnackbarMessage("No admin token found. Please log in.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }

        const apiUrl = `${config.apiBaseUrl}/admin/acceptKYCRequest`;
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'x-access-token': token,
            'kyc-id': kycId,
            'Content-Type': 'application/json',
          },
        });

        console.log("Response Status Code: ", response.status);

        if (response.status === 200) {
          const data = await response.json();

          console.log("API Response Data: ", data);

          if (data.success) {
            setSnackbarMessage(`KYC for ${kycId} has been accepted.`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);

            setTimeout(() => {
              console.log("Refreshing the page...");
              window.location.reload();
            }, 1000);
          } else {
            setSnackbarMessage(data.message || "Failed to accept KYC.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        } else {
          setSnackbarMessage("Failed to accept KYC. Please try again.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error("Error accepting KYC:", error);
        setSnackbarMessage("Error accepting KYC.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);  
      }
  };



  const columns = [
    {
      field: "kycId",
      headerName: "KYC ID",
      flex: 1,
      renderCell: (params) => (
        <Link to={`/kyc-details/${params.row.kycId}`} style={{ textDecoration: 'none', color: colors.blueAccent[500] }}>
          {params.row.kycId}
        </Link>
      ),
    },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
      renderCell: (params) => <span>{params.row.contact}</span>,
    },
    {
      field: "acceptedBy",
      headerName: "Accepted By",
      flex: 1,
      renderCell: (params) => <span>{params.row.acceptedBy || "N/A"}</span>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.status === "Pending"
                ? "orange"
                : params.row.status === "Approved"
                ? "green"
                : "red",
          }}
        >
          {params.row.status}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <MuiButton
            variant="contained"
            color="success"
            onClick={() => handleAccept(params.row.kycId)}
            disabled={params.row.status !== "Pending"}
            startIcon={<CheckCircleIcon />}
          >
            Accept
          </MuiButton>
        </>
      ),
      flex: 1,
    },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="KYC REQUESTS" subtitle="Manage KYC Requests" />
        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search by KYC ID, First Name, or Email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        mb="20px"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        {loading ? (
          renderShimmer()
        ) : (
          <DataGrid
            rows={filteredRows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 15]}
          />
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default KycRequests;
