import React, { useEffect, useState } from "react";
import { Box, InputBase, IconButton, Skeleton, Checkbox, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import Swal from "sweetalert2";
import config from '../../Config';


const ActiveStock = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [changedRows, setChangedRows] = useState([]); 

  const fetchData = async () => {
    setLoading(true);
    try {
      const apiUrl = `${config.apiBaseUrl}/admin/getActiveStock`;
      const response = await fetch(apiUrl);
      const data = await response.json();

      const formattedData = data.map((item) => ({
        id: item.instrument_token, 
        instrument_token: item.instrument_token,
        exchange: item.exchange,
        tradingsymbol: item.tradingsymbol,
        isActive: item.isActive,  
        segment: item.segment,
        name: item.name,
        last_price: item.last_price,
        open: item.ohlc.open,    
        high: item.ohlc.high,
        low: item.ohlc.low,
        close: item.ohlc.close,
      }));

      setRows(formattedData); 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, []);

  const filteredRows = rows.filter((row) =>
    row.tradingsymbol.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (instrument_token, checked, exchange) => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.instrument_token === instrument_token) {
          if (row.isActive !== checked) {
            setChangedRows((prevChangedRows) => {
              const newChangedRows = prevChangedRows.filter(
                (r) => r.instrument_token !== instrument_token
              );
              if (checked !== row.isActive) {
                newChangedRows.push({
                  instrument_token,
                  isActive: checked,
                  marketType: exchange,
                });
              }
              return newChangedRows;
            });
          }
          return { ...row, isActive: checked };
        }
        return row;
      });
    });
  };

  const handleSubmitChanges = async () => {
    try {
      const accessToken = sessionStorage.getItem("adminToken");

      const instrumentTokens = changedRows.map((row) => ({
        token: row.instrument_token,
        marketType: row.marketType, 
      }));

      const apiUrl = `${config.apiBaseUrl}/admin/removeActiveStock`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ instrumentTokens }),
      });

      if (response.status === 200) {
        const result = await response.json();
        Swal.fire({
          title: "Success!",
          text: `Successfully removed ${changedRows.length} stock(s).`,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          fetchData(); 
        });
      } else {
        const errorResult = await response.json();
        Swal.fire({
          title: "Error!",
          text: errorResult.message || "Failed to update. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }

      setChangedRows([]); 
    } catch (error) {
      console.error("Error updating Active Stock:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to update. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const columns = [
    { field: "id", headerName: "Instrument Token", flex: 1 },
    { field: "tradingsymbol", headerName: "Trading Symbol", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "exchange", headerName: "Exchange", flex: 1 },
    { field: "last_price", headerName: "Last Price", flex: 1 },
    { field: "open", headerName: "Open Price", flex: 1 },
    { field: "high", headerName: "High Price", flex: 1 },
    { field: "low", headerName: "Low Price", flex: 1 },
    { field: "close", headerName: "Close Price", flex: 1 },
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(e) => handleCheckboxChange(params.row.instrument_token, e.target.checked, params.row.exchange)}
        />
      ),
    },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="Active Stocks" subtitle="List Of Active Stocks." />

        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px" sx={{ width: "300px" }}>
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
            marginBottom: "20px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading ? renderShimmer() : <DataGrid rows={filteredRows} columns={columns} components={{ Toolbar: GridToolbar }} />}
      </Box>
      <Box
        sx={{
          marginTop: "20px",  
          marginBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",  
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitChanges}
          disabled={changedRows.length === 0}
        >
          Submit Changes
        </Button>
      </Box>
    </Box>
  );
};

export default ActiveStock;
