import React, { useEffect, useState } from "react";
import { Box, InputBase, Snackbar, Alert, IconButton, Skeleton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import config from '../../Config';
import { useNavigate } from 'react-router-dom';


const User = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = sessionStorage.getItem("adminToken");

        if (!token) {
          setSnackbarMessage("No admin token found. Please log in.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }
        const apiUrl = `${config.apiBaseUrl}/admin/getAllUsers`;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'x-access-token': token, 
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (data.userList) {
          const formattedData = data.userList.map((item) => ({
            id: item.userId, 
            userId: item.userId,
            firstName: item.first_name,
            lastName: item.last_name,
            email: item.email,
            userType: item.user_type,
            userName: item.user_name,
            shortName: item.short_name,
            orderType: item.order_type.join(', '), 
            isKYC: item.isKYC ? "Completed" : "Pending", 
          }));
          setRows(formattedData);
        } else {
          setSnackbarMessage("No user data found.");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setSnackbarMessage("Error fetching user data.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const filteredRows = rows.filter((row) => {
    const userId = row.userId ? row.userId.toLowerCase() : '';
    const firstName = row.firstName ? row.firstName.toLowerCase() : '';
    const email = row.email ? row.email.toLowerCase() : '';
    
    return (
      userId.includes(searchQuery.toLowerCase()) ||
      firstName.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase())
    );
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    { field: "userId", headerName: "User ID", flex: 1, renderCell: (params) => (
      <span 
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={() => navigate(`/UserDetails/${params.row.userId}`)} 
      >
        {params.row.userId}
      </span>
    ) },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "userType", headerName: "User Type", flex: 1 },
    { field: "userName", headerName: "User Name", flex: 1 },
    { field: "orderType", headerName: "Order Type", flex: 1 },
    {
      field: "isKYC",
      headerName: "KYC Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.isKYC === "Completed" ? "green" : "red",
          }}
        >
          {params.row.isKYC}
        </span>
      ),
    },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="USER TABLE" subtitle="Manage All Users" />
        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search by User ID, First Name, or Email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        mb="20px"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        {loading ? (
          renderShimmer()
        ) : (
          <DataGrid
            rows={filteredRows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 15]}
          />
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default User;
