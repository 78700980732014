import React, { useEffect, useState } from "react";
import { Box, InputBase, Snackbar, Alert, IconButton, Skeleton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import config from '../../Config';


const AdminUsers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const token = sessionStorage.getItem("adminToken");

        if (!token) {
          setSnackbarMessage("No admin token found. Please log in.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }
        const apiUrl = `${config.apiBaseUrl}/admin/getAdmin`;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (data.admins) {
          const formattedData = data.admins.map((item) => ({
            id: item.adminId,
            adminId: item.adminId,
            email: item.email,
            role: item.role,
            contact: item.contact || "Not Provided", 
            createdBy: item.createdBy || "N/A", 
          }));
          setRows(formattedData);
        } else {
          setSnackbarMessage("No admin data found.");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error("Error fetching admin data:", error);
        setSnackbarMessage("Error fetching admin data.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminData();
  }, []);

  const filteredRows = rows.filter((row) => {
    const adminId = row.adminId ? row.adminId.toLowerCase() : '';
    const email = row.email ? row.email.toLowerCase() : '';
    const role = row.role ? row.role.toLowerCase() : '';

    return (
      adminId.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase()) ||
      role.includes(searchQuery.toLowerCase())
    );
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    { field: "adminId", headerName: "Admin ID", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "role", headerName: "Role", flex: 1 },
    { field: "contact", headerName: "Contact", flex: 1 },
    { field: "createdBy", headerName: "Created By", flex: 1 },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="ADMIN TABLE" subtitle="Manage All Admin Users" />
        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search by Admin ID, Email, or Role"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        mb="20px"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        {loading ? (
          renderShimmer()
        ) : (
          <DataGrid
            rows={filteredRows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 15]}
          />
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminUsers;
