import React, { useState, useEffect } from "react";
import { Box, Button as MuiButton, Snackbar, Alert, Typography, Card, CardContent, Divider, Skeleton, Tab, Tabs, Chip, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayIcon from "@mui/icons-material/Replay";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import CircleIcon from "@mui/icons-material/Circle";
import { grey, green, red, yellow, blue } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import config from '../../Config';
import { useNavigate } from "react-router-dom";  
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; 



const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1a1a1a" : "#fff",
  borderRadius: "16px",
  boxShadow: theme.palette.mode === "dark" ? "0 6px 15px rgba(0, 0, 0, 0.2)" : "0 10px 20px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  marginBottom: "20px",
  border: `1px solid ${theme.palette.mode === "dark" ? "#333" : "#ccc"}`,
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "15px",
  marginBottom: "20px",
}));

const DocumentSection = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  flexWrap: "wrap",
}));

const StyledButton = styled(MuiButton)(({ theme, colorType }) => ({
  borderRadius: "50px",
  textTransform: "capitalize",
  padding: "10px 30px",
  fontWeight: "bold",
  boxShadow: "0px 6px 10px rgba(0,0,0,0.2)",
  backgroundColor:
    colorType === "accept" ? green[500] :
    colorType === "reject" ? red[500] :
    colorType === "reapply" ? blue[500] : yellow[500],
  "&:hover": {
    backgroundColor:
      colorType === "accept" ? green[700] :
      colorType === "reject" ? red[700] :
      colorType === "reapply" ? blue[700] : yellow[700],
  },
  color: "#fff",
  display: "flex",
  alignItems: "center",
}));

const KYCDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [kycData, setKycData] = useState(null); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedTab, setSelectedTab] = useState(0);
  const { kycId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = sessionStorage.getItem("adminToken");
        if (!accessToken) {
          throw new Error("Access token not found");
        }
        const apiUrl = `${config.apiBaseUrl}/admin/getKYCRequestById`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "x-access-token": accessToken,
            "kyc-id": kycId,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setKycData(data.kycData); 
      } catch (error) {
      }
    };

    fetchData();
  }, [kycId]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); 
  };

  const handleAction = async (action) => {
    const accessToken = sessionStorage.getItem("adminToken");

    try {
      let response;
      const headers = {
        "x-access-token": accessToken,
        "Content-Type": "application/json",
      };

      let status = '';
      let body = {
        kycId: kycId,
        status: '', 
      };

      if (action === "accept") {
        status = "Approved";
        body.status = status;
      } else if (action === "reject") {
        status = "Rejected";
        body.status = status;

        const { value: rejectComment } = await Swal.fire({
          title: 'Reason for Rejecting',
          input: 'textarea',
          inputLabel: 'Please provide a reason for rejection',
          inputPlaceholder: 'Type your rejection comment here...',
          inputAttributes: {
            'aria-label': 'Type your rejection comment here',
          },
          showCancelButton: true,
          confirmButtonText: 'Reject',
          cancelButtonText: 'Cancel',
          inputValidator: (value) => {
            if (!value) {
              return 'You need to provide a comment!';
            }
          },
        });

        if (rejectComment) {
          body.comment = rejectComment; 
        } else {
          return; 
        }
      } else if (action === "reapply") {
        status = "Pending";
        body.status = status;
      }

      const apiUrl = `${config.apiBaseUrl}/admin/changeKYCRequest`;
      response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body), 
      });

      const responseData = await response.json(); 
      if (response.status === 409) {
        Swal.fire({
          icon: 'warning',
          text: responseData.message || "KYC status is currently 'Rejected' and cannot be changed",
        });
        return;
      }

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to update KYC request");
      }
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: responseData.message || `KYC request ${status.toLowerCase()} successfully!`,
      });

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || "Action failed.",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const renderImage = (imageUrl) => {
    if (!imageUrl) {
      return <Skeleton variant="rectangular" width="100%" height={180} sx={{ borderRadius: "8px" }} />;
    }

    const fixedImageUrl = imageUrl.includes('?') ? imageUrl.replace('?', '') : imageUrl;

    return <img src={fixedImageUrl} alt="KYC Document" style={{ width: "300px", height: "250px", borderRadius: "8px", border: "1px solid #ddd" }} />;
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "In Progress":
        return <CircleIcon sx={{ color: yellow[500], fontSize: "24px" }} />;
      case "Accepted":
        return <CheckCircleIcon sx={{ color: green[500], fontSize: "24px" }} />;
      case "Rejected":
        return <CancelIcon sx={{ color: red[500], fontSize: "24px" }} />;
      default:
        return <CircleIcon sx={{ color: grey[500], fontSize: "24px" }} />;
    }
  };

  if (!kycData) {
    return (
      <Box m="20px">
        {/* <Typography variant="h6" color={colors.grey[100]} fontWeight="bold">
          Loading KYC Details...
        </Typography> */}
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="flex-start" mb="20px">
        <IconButton 
          onClick={() => navigate(-1)} 
          sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} 
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="KYC DETAILS" subtitle="Manage KYC Information" />
      </Box>
      {/* User Information */}
      <StyledCard>
        <CardContent>
          <InfoContainer>
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                backgroundColor: colors.grey[300],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <PersonIcon sx={{ fontSize: "40px", color: colors.primary[500] }} />
            </Box>

            {/* User Info */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography variant="h6" color={colors.grey[100]} fontWeight="bold">
                  {kycData.userData.first_name} {kycData.userData.last_name}
                </Typography>
                <Typography variant="body1" color={colors.grey[300]}>
                  {kycData.userId}
                </Typography>
              </Box>

              <Typography variant="body1" color={colors.grey[300]} sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label={kycData.kycStatus}
                  icon={renderStatusIcon(kycData.kycStatus)}
                  sx={{
                    backgroundColor:
                      kycData.kycStatus === "In Progress" ? yellow[500] :
                      kycData.kycStatus === "Accepted" ? green[500] :
                      kycData.kycStatus === "Rejected" ? red[500] :
                      grey[500],
                    color: "#000", 
                    borderRadius: "16px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    padding: "4px 12px",
                    marginTop: "8px",
                    "& .MuiChip-icon": {
                      display: "none", 
                    },
                  }}
                />
              </Typography>
            </Box>
          </InfoContainer>

          <Divider sx={{ margin: "20px 0", borderColor: colors.grey[700] }} />

          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EmailIcon sx={{ marginRight: "5px" }} />
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Email:</strong> {kycData.userData.email}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PersonIcon sx={{ marginRight: "5px" }} />
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Accepted By:</strong> {kycData.acceptedBy}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
              <PersonIcon sx={{ marginRight: "5px" }} />
              <Typography variant="body1" color={colors.grey[200]}>
                <strong>Username:</strong> {kycData.userData.user_name}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </StyledCard>

      {/* Document Tabs */}
      <StyledCard>
        <CardContent>
          <Tabs 
            value={selectedTab} 
            onChange={handleTabChange} 
            aria-label="documents tabs" 
            variant="fullWidth" 
            sx={{
              "& .MuiTab-root": {
                color: "inherit",
                fontWeight: "bold", 
              },
              "& .Mui-selected": {
                color: "inherit",
              },
            }}
          >
            <Tab label="PAN Card" />
            <Tab label="Aadhar Card" />
          </Tabs>

          {selectedTab === 0 && (
            <Box mt={2}>
              <Typography variant="h6" color={green[500]} fontWeight="bold">
                PAN Card ({kycData.panCard.number})
              </Typography>
              <DocumentSection>
                <Box width="48%" display="flex" gap="20px">
                  <Box width="100%">
                    <Typography variant="body2" color={colors.grey[200]}>
                      <strong>Front Image:</strong>
                    </Typography>
                    {renderImage(kycData.panCard.frontImage)}
                  </Box>
                  {/* <Box width="100%">
                    <Typography variant="body2" color={colors.grey[200]}>
                      <strong>Back Image:</strong>
                    </Typography>
                    {renderImage(kycData.panCard.backImage)}
                  </Box> */}
                </Box>
              </DocumentSection>
            </Box>
          )}

          {selectedTab === 1 && (
            <Box mt={2}>
              <Typography variant="h6" color={green[500]} fontWeight="bold">
                Aadhar Card ({kycData.aadharCard.number})
              </Typography>
              <DocumentSection>
                <Box width="48%" display="flex" gap="20px">
                  <Box width="100%">
                    <Typography variant="body2" color={colors.grey[200]}>
                      <strong>Front Image:</strong>
                    </Typography>
                    {renderImage(kycData.aadharCard.frontImage)}
                  </Box>
                  <Box width="100%">
                    <Typography variant="body2" color={colors.grey[200]}>
                      <strong>Back Image:</strong>
                    </Typography>
                    {renderImage(kycData.aadharCard.backImage)}
                  </Box>
                </Box>
              </DocumentSection>
            </Box>
          )}
        </CardContent>
      </StyledCard>

      {/* Action Buttons */}
      <Box display="flex" gap="20px" justifyContent="center" mt="20px">
        {kycData?.kycStatus !== "Accepted" && kycData?.kycStatus !== "Rejected" && (
          <>
            <StyledButton colorType="accept" onClick={() => handleAction("accept")} startIcon={<CheckCircleIcon />}>
              Accept
            </StyledButton>
            <StyledButton colorType="reject" onClick={() => handleAction("reject")} startIcon={<CancelIcon />}>
              Reject
            </StyledButton>
          </>
        )}

        {kycData?.kycStatus === "Rejected" && (
          <StyledButton colorType="reapply" onClick={() => handleAction("reapply")} startIcon={<ReplayIcon />}>
            Reapply
          </StyledButton>
        )}
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default KYCDetails;
