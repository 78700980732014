import React, { useEffect, useState } from "react";
import { Box, InputBase, IconButton, Skeleton, Checkbox, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; 
import { useNavigate } from "react-router-dom";  
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import Swal from "sweetalert2";
import config from '../../Config';


const DashCard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [userSelectedStocks, setUserSelectedStocks] = useState([]); 
  const navigate = useNavigate();  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const apiUrl = `${config.apiBaseUrl}/admin/getActiveStock`
        const response = await fetch(apiUrl);
        const data = await response.json();

        const formattedData = data.map((item) => ({
          id: item.instrument_token, 
          instrument_token: item.instrument_token,
          exchange: item.exchange,
          tradingsymbol: item.tradingsymbol,
          isDashCard: item.isDashCard,
          segment: item.segment,
          name: item.name,
          last_price: item.last_price,
          ohlc: item.ohlc, 
        }));

        setRows(formattedData); 
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredRows = rows.filter((row) =>
    row.tradingsymbol.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (instrument_token, checked) => {
    const row = rows.find((r) => r.instrument_token === instrument_token);

    if (row.isDashCard && !checked && !userSelectedStocks.includes(instrument_token)) {
      return; 
    }

    if (checked) {
      setUserSelectedStocks((prev) => [...prev, instrument_token]);
    } else {
      setUserSelectedStocks((prev) => prev.filter((token) => token !== instrument_token));
    }

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.instrument_token === instrument_token
          ? { ...row, isDashCard: checked } 
          : row
      )
    );
  };


  const handleUpdateClick = async () => {
    if (userSelectedStocks.length === 0) {
      Swal.fire("No stocks selected", "Please select at least one stock to update.", "warning");
      return;
    }

    const adminToken = sessionStorage.getItem("adminToken");
    if (!adminToken) {
      Swal.fire("Error", "Authentication token is missing. Please log in again.", "error");
      return;
    }

    const instrumentTokens = userSelectedStocks.map((token) => {
      const row = rows.find((r) => r.instrument_token === token);
      
      if (row && row.exchange) {
        return {
          token,
          marketType: row.exchange,  
        };
      }
      return null; 
    }).filter((item) => item !== null);  

    if (instrumentTokens.length === 0) {
      Swal.fire("Error", "Some selected stocks do not have a valid exchange.", "warning");
      return;
    }

    try {
      const apiUrl = `${config.apiBaseUrl}/admin/addDashCard`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": adminToken,  
        },
        body: JSON.stringify({ instrumentTokens }), 
      });

      if (response.status === 200) {
        const result = await response.json();
        Swal.fire(
          "Success!",
          `${instrumentTokens.length} stocks have been added to the Dash Card.`,
          "success"
        );

        setRows((prevRows) =>
          prevRows.map((row) =>
            userSelectedStocks.includes(row.instrument_token)
              ? { ...row, isDashCard: true }
              : row
          )
        );

        setUserSelectedStocks([]); 
      } else {
        const errorResponse = await response.json();
        const errorMessage = errorResponse?.message || "Failed to update. Please try again.";
        Swal.fire("Error", errorMessage, "error");
      }
    } catch (error) {
      console.error("Error updating Dash Card:", error);
      Swal.fire("Error", "An unexpected error occurred. Please try again.", "error");
    }
  };


  const columns = [
    { field: "id", headerName: "Instrument Token", flex: 1 },
    { field: "tradingsymbol", headerName: "Trading Symbol", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "exchange", headerName: "Exchange", flex: 1 },
    { field: "last_price", headerName: "Last Price", flex: 1 },
    {
      field: "isDashCard",
      headerName: "Is Dash Card",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.isDashCard || userSelectedStocks.includes(params.row.instrument_token)}
          onChange={(e) => handleCheckboxChange(params.row.instrument_token, e.target.checked)}
          disabled={params.row.isDashCard && !userSelectedStocks.includes(params.row.instrument_token)} 
        />
      ),
    },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="flex-start" mb="20px">
        <IconButton 
          onClick={() => navigate(-1)} 
          sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} 
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="Add DashCards" subtitle="List Of Stocks For Dashboard." />

        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px" sx={{ width: "300px" }}>
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
            marginBottom: "20px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading ? renderShimmer() : <DataGrid rows={filteredRows} columns={columns} components={{ Toolbar: GridToolbar }} />}
      </Box>

      <Box display="flex" justifyContent="flex-end"  mt="20px">
        <Button variant="contained" color="secondary" onClick={handleUpdateClick}>
          Update Selected Stocks
        </Button>
      </Box>
    </Box>
  );
};

export default DashCard;
